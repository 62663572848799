.main_footer {
	border-top: 1px solid #ccc;
	margin-top: 35px;
	padding: 25px 15px;
	font-size: .8em;
}

@media (max-width: 767.98px) {
	.main_footer {
		text-align: center;
	}
}

.main_footer .container-fluid .row>div #menu-footer {
	text-align: right;
}

@media (max-width: 767.98px) {
	.main_footer .container-fluid .row>div #menu-footer {
		text-align: center;
	}
}

.main_footer .container-fluid .row>div #menu-footer li {
	display: inline-block;
	margin-left: 15px;
	margin-top: 0;
}