.wrapper {
	background-color: #fff;
}

#main-nav {
	float: right;
	position: relative;
	top: 15px;
	display: none;
}

@media (min-width: 992px) {
	#main-nav {
		display: block;
	}

	:root .unten #main-nav {
		top: 9px;
	}
}

#main-nav li {
	float: left;
}

#main-nav li.current-menu-item {
	font-weight: 700;
}

#main-nav li+li {
	margin-left: 10px;
	margin-top: 0;
}

#main-nav li a {
	color: #384773;
	font-size: 1.1em;
	padding: 0.4em 15px;
	border-radius: 5px;
}

#main-nav li.menu_button_a {
	margin-right: .5em;
}

#main-nav li.menu_button_a a {
	font-size: 1em;
	top: .08em;
	position: relative;
	color: #fff;
	background-color: #384773;
}

#main-nav li.menu_button_b a {
	font-size: 1em;
	top: .08em;
	position: relative;
	color: #fff;
	padding-left: 4.4rem;
	background-color: #37a7e9;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8'%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M14 3H7.9c-.5-1.7-2-3-3.9-3-2.2 0-4 1.8-4 4s1.8 4 4 4c1.9 0 3.4-1.3 3.9-3H11v2h2V5h1V3zM4 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z'/%3E%3C/svg%3E");
	background-size: 1em;
	background-repeat: no-repeat;
	background-position: .8em center;
}

:root .unten #main-nav li.menu_button_b a {
	font-size: 1em;
}



.moby-wrap .moby-menu ul.menu ul {
	position: relative !important;
}

.moby-wrap .moby-menu ul.menu li.menu_button_a a,
.moby-wrap .moby-menu ul.menu li.menu_button_b a {
	background-color: #fff;
	color: #37a7e9;
	margin-left: 30px;
	margin-right: 24px;
	border-radius: 5px;
	margin-top: 18px;
	text-align: center;
	padding: 12px;
}

#moby-button {
	float: right;
	margin-top: 2px;
	background-color: #37a7e9;
	height: 44px;
	width: 44px;
	border-radius: 5px;
	text-indent: -999px;
	overflow: hidden;
	cursor: pointer;
	background-image: url("data:image/svg+xml,%3C!-- Generator: Adobe Illustrator 24.0.1, SVG Export Plug-In --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='79.5px' height='65px' viewBox='0 0 79.5 65' style='enable-background:new 0 0 79.5 65;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23FFFFFF;stroke-width:6;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cdefs%3E%3C/defs%3E%3Cg%3E%3Cline class='st0' x1='0' y1='3' x2='79.1' y2='3'/%3E%3C/g%3E%3Cg%3E%3Cline class='st0' x1='0.4' y1='32.5' x2='79.5' y2='32.5'/%3E%3C/g%3E%3Cg%3E%3Cline class='st0' x1='0.4' y1='62' x2='79.5' y2='62'/%3E%3C/g%3E%3C/svg%3E%0A");
	background-size: 60%;
	background-position: center;
	background-repeat: no-repeat;
}

@media (min-width: 992px) {
	#moby-button {
		display: none;
	}
}

@media (min-width: 768px) {
	#moby-button {
		margin-top: 8px;
	}
}

#main-nav #menu-ostsee li:last-child {
	margin-right: .5em;
}

#main-nav #menu-ostsee li:last-child a {
	font-size: 1em;
	top: .08em;
	position: relative;
	color: #fff;
	background-color: #384773;
}

#main-nav-left {
	float: left;
	position: relative;
	top: 15px;
	left: 50px;
	display: none;
}

@media (min-width: 992px) {
	#main-nav-left {
		display: block;
	}
}

#main-nav-left li {
	float: left;
}

#main-nav-left li.current-menu-item {
	font-weight: 700;
}

#main-nav-left li+li {
	margin-left: 10px;
	margin-top: 0;
}

#main-nav-left li a {
	color: #384773;
	font-size: 1.1em;
	padding: 0.4em 15px;
	border-radius: 5px;
}

@media (min-width: 992px) {
	.header_top_wrapper {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 998;
		background-color: #fff;
	}

	:root .unten .header_top_wrapper {
		-webkit-box-shadow: 0 0.2rem 0.5rem rgba(51, 51, 51, 0.15);
		box-shadow: 0 0.2rem 0.5rem rgba(51, 51, 51, 0.15);
	}
}

.header_top {
	padding: 25px 15px;
	-webkit-transition: padding .3s;
	transition: padding .3s;
}

@media (min-width: 992px) {
	.header_top {
		padding: 25px 25px;
	}
}

.header_top .logo {
	float: left;
	height: 40px;
	-webkit-transition: height .3s;
	transition: height .3s;
}

@media (min-width: 768px) {
	.header_top .logo {
		height: 60px;
	}
}

:root .unten .header_top {
	padding: 15px 15px;
}

:root .unten .header_top .logo {
	height: 50px;
}


.dropotron {
	background: #37a7e9;
	border-radius: 0.5em;
	list-style: none;
	margin: 0;
	min-width: 10rem;
	-webkit-box-shadow: 0 0.5rem 1rem rgba(51, 51, 51, 0.22);
	box-shadow: 0 0.5rem 1rem rgba(51, 51, 51, 0.22);
}

.dropotron>li {
	margin: 0;
	padding: 0;
	position: relative;
}

.dropotron>li:first-child {
	border-top: 0;
}

.dropotron>li a {
	color: #fff;
	font-weight: 400;
	font-size: .9em;
	display: block;
	padding: 0.65em 2em 0.65em 2em;
	text-decoration: none;
	background-color: transparent;
	-webkit-transition: background-color .3s;
	transition: background-color .3s;
}

.dropotron>li a:hover {
	background-color: rgba(255, 255, 255, 0.15);
}

.dropotron.level-0 {
	margin-top: 1em;
}

.dropotron.level-0:before {
	content: '';
	position: absolute;
	border-bottom: solid 0.5em #37a7e9;
	border-left: solid 0.5em transparent;
	border-right: solid 0.5em transparent;
	top: -0.4em;
	margin-left: 80px;
}

.dropotron>li.active>a,
.dropotron>li:hover>a {
	color: #fff;
}

.cf:after {
	content: "";
	display: table;
	clear: both;
}

* MOBY.LESS VERSION 2.0.7 */ .moby-overlay {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	position: fixed;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2000;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}

.moby-overlay.dark {
	background: rgba(56, 71, 115, 0.7);
}

.moby-overlay.light {
	background: rgba(255, 255, 255, 0.65);
}

.moby-overlay.moby-overlay-active {
	visibility: visible !important;
	opacity: 1 !important;
}

.moby-body-fixed {
	overflow: hidden !important;
}

.moby {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	background: #37a7e9;
	-webkit-transition: all 0.4s;
	transition: all 0.4s;
	overflow: auto;
}

.moby * {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.moby ul {
	list-style: none;
	padding-top: 15px;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.moby ul ul {
	display: none;
	padding-top: 0;
}

.moby ul li {
	margin-top: 0;
}

.moby ul li ul {
	background-color: #1998e3;
	padding-top: 15px;
	padding-bottom: 15px;
}

.moby ul li ul a {
	padding: 10px 90px 10px 30px;
	font-weight: normal;
	font-size: 1em;
}

.moby ul li a {
	position: relative;
	color: #fff;
	text-decoration: none;
	font-size: 1.3;
	font-weight: normal;
	display: block;
	padding: 10px 90px 10px 30px;
	text-align: left;
}

.moby ul li a .moby-expand {
	position: absolute;
	width: 66px;
	top: 0;
	right: 0;
	height: 100%;
	text-align: center;
	padding: 10px;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='6.6px' height='4px' viewBox='0 0 6.6 4' style='enable-background:new 0 0 6.6 4;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23FFFFFF;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cdefs%3E%3C/defs%3E%3Cpolyline class='st0' points='0.4,0.4 3.3,3.3 6.2,0.4 '/%3E%3C/svg%3E%0A");
	background-size: 30%;
	background-repeat: no-repeat;
	background-position: center;
}

.moby ul li a .moby-expand.moby-submenu-open {
	background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='6.6px' height='4px' viewBox='0 0 6.6 4' style='enable-background:new 0 0 6.6 4;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23FFFFFF;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cdefs%3E%3C/defs%3E%3Cpolyline class='st0' points='0.4,3.6 3.3,0.7 6.2,3.6 '/%3E%3C/svg%3E%0A");
}

.moby ul li a .moby-expand i {
	font-size: 15px;
}

.moby ul li a .moby-expand span {
	opacity: 0;
}

.moby .moby-close {
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 400;
	padding: 18px 30px;
	background: #384773;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
}

.moby .moby-close .moby-close-icon {
	font-weight: bold;
	margin-right: 15px;
	position: relative;
	height: 30px;
	width: 30px;
	margin-top: 3px;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.moby .moby-close .moby-close-icon::before {
	content: '';
	position: absolute;
	left: 0;
	width: 100%;
	height: 2px;
	background: #fff;
	top: 50%;
	margin-top: -1px;
}

.moby .moby-close .moby-close-icon::after {
	content: '';
	position: absolute;
	left: 50%;
	height: 100%;
	width: 2px;
	background: #fff;
	top: 0%;
	margin-left: -1px;
}

.moby.moby-hidden {
	display: none;
}

.moby.moby-demo.moby-active {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

.moby.right-side {
	position: fixed;
	z-index: 3000;
	top: 0;
	height: 100%;
	width: 30%;
	right: 0;
	min-width: 280px;
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
}

.moby.right-side.moby-active {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

.moby.fade {
	position: fixed;
	z-index: 3000;
	top: 0;
	height: 100%;
	left: 0px;
	width: 100%;
	visibility: hidden;
	opacity: 0;
}

.moby.fade ul {
	width: 100%;
}

.moby.fade.moby-active {
	opacity: 1;
	visibility: visible;
}

.moby.left-side {
	position: fixed;
	z-index: 3000;
	top: 0;
	height: 100%;
	left: 0px;
	width: 30%;
	min-width: 280px;
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
}

.moby.left-side.moby-active {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

.moby.top-full {
	position: fixed;
	z-index: 3000;
	top: 0;
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
	height: 100%;
	left: 0px;
	width: 100%;
}

.moby.top-full ul {
	width: 100%;
}

.moby.top-full.moby-active {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

.moby.bottom-full {
	position: fixed;
	z-index: 3000;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	height: 100%;
	left: 0px;
	width: 100%;
}

.moby.bottom-full ul {
	width: 100%;
}

.moby.bottom-full.moby-active {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

.moby.grow-out {
	position: fixed;
	z-index: 3000;
	top: 0;
	height: 100%;
	left: 0px;
	width: 100%;
	-webkit-transform: scale(0.7);
	transform: scale(0.7);
	visibility: hidden;
	opacity: 0;
}

.moby.grow-out ul {
	width: 100%;
}

.moby.grow-out.moby-active {
	opacity: 1;
	visibility: visible;
	-webkit-transform: scale(1);
	transform: scale(1);
}

.moby.shrink-in {
	position: fixed;
	z-index: 3000;
	top: 0;
	height: 100%;
	left: 0px;
	width: 100%;
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
	visibility: hidden;
	opacity: 0;
}

.moby.shrink-in ul {
	width: 100%;
}

.moby.shrink-in.moby-active {
	opacity: 1;
	visibility: visible;
	-webkit-transform: scale(1);
	transform: scale(1);
}