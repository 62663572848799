.search-page-layout-v1 {
	background-color: var(--color-grey-light);
	margin-top: var(--page-margin-top);

	.search-mainform {
		display: none;

		@media (min-width:991px) {
			display: block;
		}

	}
}