.widget-header {
	background-color: var(--widget-header-bg-color);
	border: 1px solid var(--widget-border-color);
	padding: 10px;
	margin-bottom: 0;
	border-bottom: none;
	font-size: var(--h5-font-size);
	font-weight: var(--h2-font-weight);
	text-decoration: var(--h2-text-decoration);
	text-transform: var(--h2-text-transform);
	color: var(--widget-header-title-font-color);
}

.widget-block {
	background-color: var(--widget-block-bg-color);
	padding: 15px;
	border: 1px solid var(--widget-border-color);


	.title {
		font-size: var(--h5-font-size);
		font-weight: var(--h3-font-weight);
		text-decoration: var(--h3-text-decoration);
		text-transform: var(--h3-text-transform);
		color: var(--widget-block-title-font-color);
		margin-bottom: 1rem;
	}

	.unit-map {
		width: 100%;
		height: 380px;
		position: relative;
	}
}